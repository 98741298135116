import React from 'react';
import { Route } from 'react-router-dom';
import CustomizeDocumentLayout from '../../../components/templates/customize-document/CustomizeDocumentLayout';
import DigitalSignatureLayout from '../../../components/templates/settings/digital-signature/DigitalSignatureLayout';
import ManageUsersLayout from '../../../components/templates/settings/manage-users/ManageUsersLayout';
import ManageIVALayout from '../../../components/templates/settings/manage-iva/ManageIVALayout';
import ManageAPILayout from '../../../components/templates/settings/manage-api/ManageAPILayout';
import { CUSTOMIZE_DOCUMENT, SETTINGS, USERS } from '../../../constants/url';
import ItensLayout from '../../../components/templates/settings/itens/ItensLayout';
import EditItensLayout from '../../../components/templates/settings/itens/edit/EditItensLayout';
import ViewItemLayout from '../../../components/templates/settings/itens/view/ViewItemLayout';
import BillingPreferencesLayout from '../../../components/templates/settings/company/billing-preferences/BillingPreferencesLayout';
import ExportSaftLayout from '../../../components/templates/settings/export-saft/ExportSaftLayout';

/**
 * Creates the routes necessary to render the account settings
 *
 * @returns {Array} routes created on the AccountSettings submenu
 */
export const AccountSettingsRoutes = (layoutProps) => [
  <Route exact path={[CUSTOMIZE_DOCUMENT]} key={CUSTOMIZE_DOCUMENT}>
    <CustomizeDocumentLayout documentsTab={'CustomizeDocument'} />
  </Route>,
  <Route path={[SETTINGS.DIGITAL_SIGNATURE]} key={SETTINGS.DIGITAL_SIGNATURE}>
    <DigitalSignatureLayout documentsTab={'DigitalSignature'} />
  </Route>,
  <Route exact path={[USERS.LIST]} key={USERS.LIST}>
    <ManageUsersLayout
      documentsTab={'ManageUsers'}
      windowLocation={layoutProps.windowLocation}
    />
  </Route>,
  <Route path={[SETTINGS.REGIME_IVA]} key={SETTINGS.REGIME_IVA}>
    <ManageIVALayout documentsTab={'RegimeIVA'} />
  </Route>,
  <Route path={[SETTINGS.MANAGE_API]} key={SETTINGS.MANAGE_API}>
    <ManageAPILayout documentsTab={'ManageAPI'} />
  </Route>,

  <Route exact path={[SETTINGS.ITENS]} key={SETTINGS.ITENS}>
    <ItensLayout
      documentsTab={'Items'}
      windowLocation={layoutProps.windowLocation}
    />
  </Route>,

  <Route exact path={[SETTINGS.NEW_ITEM]} key={SETTINGS.NEW_ITEM}>
    <EditItensLayout
      documentsTab={'NewItem'}
      windowLocation={layoutProps.windowLocation}
      language={layoutProps.language}
    />
  </Route>,

  <Route exact path={[SETTINGS.EDIT_ITEM]} key={SETTINGS.EDIT_ITEM}>
    <EditItensLayout
      documentsTab={'EditItem'}
      windowLocation={layoutProps.windowLocation}
      language={layoutProps.language}
    />
  </Route>,

  <Route exact path={[SETTINGS.VIEW_ITEM]} key={SETTINGS.VIEW_ITEM}>
    <ViewItemLayout
      documentsTab={'ViewItem'}
      windowLocation={layoutProps.windowLocation}
      language={layoutProps.language}
    />
  </Route>,
  <Route
    exact
    path={[SETTINGS.BILLING_PREFERENCES]}
    key={SETTINGS.BILLING_PREFERENCES}
  >
    <BillingPreferencesLayout
      documentsTab={'BillingPreferences'}
      windowLocation={layoutProps.windowLocation}
      language={layoutProps.language}
    />
  </Route>,

  <Route path={[SETTINGS.EXPORT_SAFT]} key={SETTINGS.EXPORT_SAFT}>
    <ExportSaftLayout />
  </Route>,
];
