import React, { useState } from 'react';
import SearchForm from '../../../molecules/forms/SearchForm';
import { injectIntl } from 'react-intl';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import Button from '../../../atoms/buttons/Button';
import Anchor from '../../../atoms/anchors/Anchor';

const SearchBox = (props) => {
  const [currentTextSearch, setCurrentTextSearch] = useState(
    props.filters.text
  );
  const [previousTextSearch, setPreviousTextSearch] = useState(
    props.filters.text
  );
  const [textChanged, setTextChanged] = useState(false);

  /**
   * Submit form preventing page reload.
   * @function
   * @param {object} e - event.
   */
  const submitSearch = (e) => {
    e.preventDefault();

    // prevent request
    if (textChanged === false) {
      return;
    }

    setTextChanged(false);
    setPreviousTextSearch(currentTextSearch);

    props.getDocumentsByTextInput(currentTextSearch);
  };

  /**
   * Child input onChange event.
   * @function
   * @param {object} e - event.
   */
  const onChangeInputValue = (e) => {
    const newTextInputValue = e.target.value;

    setTextChanged(newTextInputValue !== previousTextSearch);
    setCurrentTextSearch(e.target.value);
  };

  const { filters } = props;

  const mobileDisplay = openMobileSideContent(
    'mobileFilters',
    props.mobileSideContent
  );

  if (mobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='advanced-search --mol'>
      <div className='row search-bar-row margin-bottom mx-0'>
        <div
          className={`${
            props.newLayout || props.anchorActive
              ? 'col-lg-9 col-12 px-0 ps-lg-0 pe-lg-2'
              : 'col-12 px-0'
          }`}
        >
          <SearchForm
            id='table'
            searchTerm={filters.text}
            textChanged={textChanged}
            onChangeInputValue={onChangeInputValue}
            onSubmit={submitSearch}
            placeholder={props.placeholder}
          />
        </div>

        {props.newLayout && (
          <div className='col-lg-3 col-12 px-0 ps-lg-2 pe-lg-0 mt-2 mt-lg-0'>
            <Button
              className='button button-primary d-block'
              onClick={props.handleButtonClick}
              disabled={props.isButtonDisabled}
            >
              {props.intl.messages[props.buttonLabel]}
            </Button>
          </div>
        )}

        {props.anchorActive && (
          <div className='col-lg-3 col-12 px-0 ps-lg-2 pe-lg-0 mt-2 mt-lg-0'>
            <Anchor
              id={`${props.id}_newContact`}
              className='button button-primary d-flex align-items-center justify-content-center p-0'
              href={props.anchorHref}
            >
              {props.intl.messages[props.anchorLabel]}
            </Anchor>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(SearchBox);
