import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill-new';
import { injectIntl } from 'react-intl';
import ToolbarEditor, { formats, modules } from './toolbar/ToolbarEditor';

/**
 * Editor Component using Quill Library
 */
const TextEditor = ({ defaultValue, variables, onChange, intl, disabled }) => {
  const [value, setValue] = useState(defaultValue);
  const [inputDisabled, setInputDisabled] = useState(false);

  /**
   * Change editor state
   * @param {string} content
   */
  const handleOnChange = (content) => {
    setValue(content);
    onChange(content);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setInputDisabled(disabled);
  }, [disabled]);

  return (
    <div className='text-editor'>
      <ToolbarEditor customVariables={variables} disabled={inputDisabled} />

      <ReactQuill
        theme='snow'
        value={value}
        onChange={handleOnChange}
        placeholder={intl.messages['defaultContentEmail']}
        modules={modules}
        formats={formats}
        readOnly={inputDisabled}
      />
    </div>
  );
};

TextEditor.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  variables: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default injectIntl(TextEditor);
