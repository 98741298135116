import React, { Component } from 'react';
import SearchBox from '../../organisms/containers/guides/SearchBox';
import RightMenu from '../../organisms/containers/guides/RightMenu';
import Table from '../../organisms/tables/Table';
import MobileMenu from '../../organisms/containers/mobile/MobileMenu';
import ToolBar from '../../organisms/toolbar/ToolBar';
import { parseAndSetSearchFilters } from '../invoices/util/filters/filtersManager';
import { guidesSortOptions } from '../../templates/invoices/util/filters/defaultFilters';
import { documentsPerPage } from '../util/listing/items-per-page/itemsPerPageManager';
import { sortDocuments } from '../util/listing/sort/sortDocumentsManager';
import { fetchDocumentFavorites } from '../util/listing/favorites/favoritesManager';
import { fetchDocumentsAfterBulk } from '../util/listing/bulks/bulksManager';
import { updateQueryStringWithFilters } from './util/api/queryStringManager';
import { updateListing } from '../util/listing/values-with-vat/valuesWithVatManager';
import { fetchTypeDocuments } from '../util/listing/filters/type/typeManager';
import { fetchStatusDocuments } from '../util/listing/filters/status/statusManager';
import { fetchValueDocuments } from '../util/listing/filters/value/valueManager';
import {
  fetchClientDocuments,
  fetchClientsMatch,
} from '../util/listing/filters/client/clientManager';
import * as request from '../invoices/util/api/request';
import * as stateManager from '../state-manager/stateManager';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import * as queryStringManager from '../util/api/queryStringManager';
import * as templateInformation from '../helper/templateInformationHelper';
import * as favoritesHelper from '../../organisms/dropdowns/util/favoritesHelper';
import { fetchDateDocuments } from '../util/listing/filters/date/dateManager';
import { FormattedMessage } from 'react-intl';
import TableContainer from '../../organisms/containers/table-container/TableContainer';

/**
 * Main component (entry point) for guides listing
 * @class
 * Renders all the main elements
 * Sets the main app state
 * Holds the main functions to change the state and perform external requests
 */
export default class GuidesLayout extends Component {
  _isMounted = false;

  /**
   * @constructor
   * Sets all the app state
   * @param {object} props - React props object (account_id, user_id, location object)
   */
  constructor(props) {
    super(props);

    this.state = {
      accountId: props.accountId,
      userId: props.userId,
      userEmail: props.userEmail,
      language: props.language,
      windowLocation: props.windowLocation,
      defaultItemsPerPage: props.itemsPerPage,
      isLoading: true,
      isLoadingFirstRequest: true,
      isBulkLoading: false,
      hasBulkFinished: false,
      globalResetKey: 1,
      globalTableKey: 1,
      documents: [],
      documentsSelected: new Set(),
      documentsDeselected: new Set(),
      allDocumentsSelected: false,
      prevAllDocumentsSelected: false,
      numberOfDocuments: 0,
      numberOfPages: null,
      accountSettings: {},
      filters: parseAndSetSearchFilters(props),
      favoriteUID: favoritesHelper.parseAndSetFUID(props),
      filterUpdatedFlag: favoritesHelper.parseAndSetFilterUpdatedFlag(props),
      defaultFavorites: [],
      customFavorites: [],
      totals: {},
      clients: [],
      clientSearchTerm: '',
      showMobileMenu: false,
      mobileSideContent: '',
    };

    this.initialState = JSON.parse(JSON.stringify(this.state));
    this.fetchSecondaryData = this.fetchSecondaryData.bind(this);
    this.setDocumentsAndTotals = this.setDocumentsAndTotals.bind(this);
    this.setClientsMatch = this.setClientsMatch.bind(this);
    this.getDocumentsByClient = this.getDocumentsByClient.bind(this);
    this.getDocumentsByTextInput = this.getDocumentsByTextInput.bind(this);
    this.getDocumentsByType = this.getDocumentsByType.bind(this);
    this.getDocumentsByDate = this.getDocumentsByDate.bind(this);
    this.getDocumentsByStatus = this.getDocumentsByStatus.bind(this);
    this.getDocumentsByValue = this.getDocumentsByValue.bind(this);
    this.getClientsMatch = this.getClientsMatch.bind(this);
    this.setItemsPerPage = this.setItemsPerPage.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.fetchDefaultListingData = this.fetchDefaultListingData.bind(this);
    this.sortDocumentsByPropertyAndOrder =
      this.sortDocumentsByPropertyAndOrder.bind(this);
    this.getDocumentsPage = this.getDocumentsPage.bind(this);
    this.getUpdatedSelectionInfo = this.getUpdatedSelectionInfo.bind(this);
    this.selectAllDocuments = this.selectAllDocuments.bind(this);
    this.selectAllDocumentsAndRefreshSelection =
      this.selectAllDocumentsAndRefreshSelection.bind(this);
    this.setshowValuesWithVat = this.setshowValuesWithVat.bind(this);
    this.deselectAllDocuments = this.deselectAllDocuments.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.updateAndApplyFilter = this.updateAndApplyFilter.bind(this);
    this.updateAfterBulk = this.updateAfterBulk.bind(this);
    this.setBulkLoading = this.setBulkLoading.bind(this);
    this.setBulkFinished = this.setBulkFinished.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
    const { accountId, language, filters, windowLocation } = this.state;
    const { documentsTab } = this.props;

    const { accountSettings, documents } = await request.fetchCriticalData(
      accountId,
      language,
      documentsTab,
      filters,
      windowLocation
    );

    // Critical request went wrong
    if (typeof documents === 'undefined') {
      return;
    }

    // Totals should be set only when there are no selection
    let totals = documents.totals;
    if (this.state.documentsSelected.size !== 0) {
      totals = this.state.totals;
    }

    if (this._isMounted) {
      this.setState({
        accountSettings: accountSettings,
        documents: documents.guides,
        totals: totals,
        numberOfDocuments: totals.count,
        documentsNumber: totals.count,
        numberOfPages: documents.totals.pages,
        isLoading: false,
        isLoadingFirstRequest: false,
      });
    }

    await this.fetchSecondaryData();
  }

  /**
   * Perform requests to get all the secondary data for the app.
   * @function
   */
  async fetchSecondaryData() {
    if (this._isMounted) {
      const defaultFavorites = await request.fetchDefaultFavorites(
        this.props.documentsTab
      );

      //Set the default
      favoritesHelper.setDefaultFlag(defaultFavorites, true);

      this.setState({ defaultFavorites: defaultFavorites.favorites || [] });

      const customFavorites = await request.fetchCustomFavorites(
        this.props.documentsTab
      );

      //Set the default flag
      favoritesHelper.setDefaultFlag(customFavorites, false);

      this.setState({ customFavorites: customFavorites.favorites || [] });
    }
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by text
   * @function
   * @param {string} searchTerm - Page number to be requested.
   */
  async getDocumentsByTextInput(searchTerm) {
    const queryStringParams = { text: searchTerm, page: 1 };
    const { stateCopy, response } = await stateManager.setComponentState(
      queryStringParams,
      this.state,
      this.props.documentsTab
    );

    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;

    this.clearSelection(stateCopy);
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document type
   * @function
   * @param {object} types - JSON with type filters applied.
   */
  async getDocumentsByType(types) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      types: types,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          type: types,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchTypeDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by client name
   * @function
   * @param {object} names - array of names to look for.
   */
  async getDocumentsByClient(clients) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      clientList: clients,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          clientList: clients,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchClientDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document status
   * @function
   * @param {object} status - JSON with status filters applied.
   * @param {boolean} archived - archived filters applied.
   * @param {boolean} nonArchived - non_archived filters applied.
   */
  async getDocumentsByStatus(status, archived, nonArchived) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      status: status,
      archived: archived,
      nonArchived: nonArchived,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          status: status,
          archived: archived,
          nonArchived: nonArchived,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchStatusDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document value
   * @function
   * @param {object} documentTotalRange - JSON with value range filter.
   * @param {boolean} showIva - boolean with the position of the IVA's toggle
   */
  async getDocumentsByValue(documentTotalRange, showIva) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      documentTotalRange: documentTotalRange,
      showIva: showIva,
    };
    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          documentTotalRange: documentTotalRange,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchValueDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by issue Date type
   * @function
   * @param {object} types - Object with from and to filters applied.
   * @param {boolean} reset - reset option. True To reset the search; False to procede normaly
   */
  async getDocumentsByDate(
    loadedAt,
    dueDate,
    loadedAtLabel,
    dueDateLabel,
    reset
  ) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      loadedAt: loadedAt,
      loadedAtLabel: loadedAtLabel,
      reset: reset,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );

    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          loadedAt: loadedAt,
          loadedAtLabel: loadedAtLabel,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalSummaryKey: prevState.globalSummaryKey + 1,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchDateDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Gets clients suggestions for value from the Backend and then merge the results with
   * the current selected clients. The selected ones should be at the first positions.
   * @function
   * @param {string} value - search Term to get suggestion
   * @param {object} clientsToFilter - list of clients that already been selected
   */
  async getClientsMatch(value, clientsToFilter) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      value: value,
      clientsToFilter: clientsToFilter,
    };
    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    await fetchClientsMatch(
      searchInformation,
      accountInformation,
      extraInformation,
      this.setClientsMatch
    );
  }

  /**
   * Prepare filters for API request and change items per page
   * @function
   * @param {string} itemsToShow - items per page requested.
   */
  async setItemsPerPage(itemsToShow) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      itemsPerPage: itemsToShow,
    };

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          itemsPerPage: itemsToShow,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    await documentsPerPage(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * sort by document property and order
   * @function
   * @param {string} sort - document property.
   * @param {string} sortOrder - sort order applied.
   */
  async sortDocumentsByPropertyAndOrder(sort, sortOrder) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      sortArgument: sort,
      sortOrderArgument: sortOrder,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sort: sort,
          sortOrder: sortOrder,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await sortDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Check page number boundaries and prepare filters for API request
   * Search specific page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  async getDocumentsPage(nextPage) {
    const stateCopy = this.state;
    stateCopy.filters.page = nextPage;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalTableKey += 1;
    stateCopy.isLoading = true;
    this.setState({ ...this.state, stateCopy });

    queryStringManager.updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    queryStringManager.updateQueryStringParam(
      'page',
      nextPage,
      this.state.windowLocation
    );

    const documentsInformation = {
      documentsDeselected: this.state.documentsDeselected,
      prevAllDocumentsSelected: this.state.prevAllDocumentsSelected,
    };

    const response = await request.fetchDocumentsPerPage(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      documentsInformation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Handles the click over the toggle filter, and sets the state accordingly.
   * @function
   * @param {boolean} toggleChecked - represents the state of the toggle.
   */
  setshowValuesWithVat(toggleChecked) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      toggleChecked: toggleChecked,
    };

    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () =>
      this.setState(
        (prevState) => ({
          filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
          globalSummaryKey: prevState.globalSummaryKey + 1,
          globalTableKey: prevState.globalTableKey + 1,
        }),
        () =>
          this.setState({
            filters: {
              ...this.state.filters,
              showTotalWithIVA: toggleChecked,
            },
          })
      );

    updateListing(
      prevStateCallback,
      searchInformation,
      extraInformation,
      this.sortDocumentsByPropertyAndOrder
    );
  }

  /**
   * Prepare filters for API request and update summary
   * @function
   * @param {object} documentIds - array with document ids.
   */
  async getUpdatedSelectionInfo(documentIds) {
    this.setState((prevState) => {
      return {
        documentsSelected: documentIds,
        globalSummaryKey: (prevState.globalSummaryKey += 1),
      };
    });

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const documentsInformation = templateInformation.getDocumentsInformation(
      this.state
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const response = await request.updateSelectionInfo(
      accountInformation,
      documentsInformation,
      searchInformation
    );

    this.setState({
      totals: response.totals,
      documentsNumber: response.totals.count,
    });
  }

  /**
   * Sets the state of the component, regarding the 'Select All' checkbox
   */
  selectAllDocuments = () => {
    const stateCopy = this.state;
    stateCopy.allDocumentsSelected = true;
    stateCopy.prevAllDocumentsSelected = false;
    stateCopy.documentsDeselected.clear();
    this.setState({ ...this.state, stateCopy });
  };

  /**
   * Updates the state of the App when the user Selects All documents to perform a bulk action
   * @function
   */
  async selectAllDocumentsAndRefreshSelection() {
    this.setState(
      (prevState) => {
        prevState.documentsDeselected.clear();

        return {
          allDocumentsSelected: true,
          prevAllDocumentsSelected: false,
        };
      },
      async () =>
        await this.getUpdatedSelectionInfo(this.state.documentsSelected)
    );
  }

  /**
   * Updates the state of the App when the user deselects some documents, after
   * having the select all pages checkbox checked.
   * @function
   */
  deselectAllDocuments() {
    const stateCopy = this.state;
    stateCopy.allDocumentsSelected = false;
    stateCopy.prevAllDocumentsSelected = true;
    this.setState({ ...this.state, stateCopy });
  }

  /**
   * Updates the state of the App when the user clicks the X on the toolbar,
   * after selecting some documents. Essentially, restores the app state.
   * @function
   * @param {object} newState - state object copy
   */
  clearSelection(newState = this.state) {
    const stateCopy = newState;
    stateCopy.allDocumentsSelected = false;
    stateCopy.prevAllDocumentsSelected = false;
    stateCopy.documentsSelected.clear();
    stateCopy.documentsDeselected.clear();
    stateCopy.documentsNumber = stateCopy.numberOfDocuments;
    this.setState({ ...this.state, stateCopy });
  }

  /**
   * Updates current filters with Favorite filters, updates query strings
   * and reloads documents
   * @param {object} newFilters - Favorite filters to be applied
   * @param {string} uid - Favorite UID that filters are being applied
   */
  async updateAndApplyFilter(newFilters, uid) {
    const extraInformation = {
      newFilters: newFilters,
      uid: uid,
    };
    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const updateCallback = () => {
      this.clearSelection();

      updateQueryStringWithFilters(
        newFilters,
        searchInformation.windowLocation
      );

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          ...newFilters,
        },
        uid: uid,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchDocumentFavorites(
      updateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Updates current favorite UID and removes flag
   * @param {string} uid - favorite uid to update
   */
  updateFavoriteUID = (uid) =>
    this.setState({ favoriteUID: uid, filterUpdatedFlag: false });

  /**
   * Updates Favorite Flag
   * @param {boolean} filterUpdatedFlag - New Value of filter Updated Flag
   */
  updateFavoriteFlag = (filterUpdatedFlag) => {
    this.setState({ filterUpdatedFlag });
    queryStringManager.updateFilterUpdatedFlagQuery(
      filterUpdatedFlag,
      this.state.windowLocation
    );
  };

  /**
   * Removes a favorite from Custom favorites List
   * @param {object} uidToRemove - the favorite uid to remove.
   * @param {boolean} removeCurrentFavorite - if current favorite uid should be removed from state
   */
  removeFromCustomFavoritesList = (uidToRemove, removeCurrentFavorite) => {
    this.setState((prevState) => {
      const favoriteUID = removeCurrentFavorite ? '' : prevState.favoriteUID;
      return {
        customFavorites: prevState.customFavorites.filter(
          ({ uid }) => uid !== uidToRemove
        ),
        favoriteUID,
      };
    });
  };

  /**
   * Updates Filter name with the given uid
   * @param uid - Favorite uid receiving the update
   * @param name - name to update
   */
  updateCustomFavoritesList = (uid, name) => {
    this.setState((prevState) => {
      return {
        customFavorites: favoritesHelper.updateNameByUid(
          prevState.customFavorites,
          uid,
          name
        ),
      };
    });
  };

  /**
   * Adds a new favorite locally
   * @param {object} newFavorite - the new favorite to add.
   */
  addToCustomFavoritesList = (newFavorite) => {
    this.setState((prevState) => {
      prevState.customFavorites.push(newFavorite);
      return { customFavorites: prevState.customFavorites };
    });
  };

  /**
   * Sets the app state taking into account the API response.
   * @function
   * @param {object} response - JSON with search result (documents & summary).
   */
  setDocumentsAndTotals(response) {
    // Totals should be set only when there are no selection
    let totals = response.totals;
    let numberOfDocuments = totals.count;
    if (this.state.documentsSelected.size !== 0) {
      totals = this.state.totals;
      numberOfDocuments = this.state.numberOfDocuments;
    } else if (this.state.prevAllDocumentsSelected) {
      numberOfDocuments = this.state.numberOfDocuments;
    }

    if (this._isMounted) {
      this.setState({
        documents: response.guides,
        totals: totals,
        numberOfDocuments: numberOfDocuments,
        documentsNumber: totals.count,
        numberOfPages: response.totals.pages,
        isLoading: false,
      });
    }
  }

  /**
   * Sets the app state using the current response client list.
   * @function
   * @param {object} response - JSON with search result (clients).
   * @param {string} value - search Term to get suggestion.
   */
  setClientsMatch(response, value) {
    if (this._isMounted) {
      this.setState({ clientSearchTerm: value, clients: response.clients });
    }
  }

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  resetAllFilters() {
    const queryStringWithPage = queryStringManager.buildQueryStringWithPage(
      this.state.filters.page
    );
    queryStringManager.clearQueryString(
      this.state.windowLocation,
      queryStringWithPage
    );
    this.clearSelectedFavorite();
    this.clearDocumentsSelected();
    this.clearListingData(queryStringWithPage);
  }

  /**
   * Clears the selected Favorite.
   * @function
   */
  clearSelectedFavorite() {
    this.setState({ favoriteUID: '' });
  }

  /**
   * Clears the documents selected.
   * @function
   */
  clearDocumentsSelected() {
    const newGlobalSummaryKey = this.state.globalSummaryKey + 1;
    const newGlobalTableKey = this.state.globalTableKey + 1;

    this.setState({
      documentsSelected: new Set(),
      documentsDeselected: new Set(),
      documentsNumber: this.state.totals.count,
      allDocumentsSelected: false,
      prevAllDocumentsSelected: false,
      globalSummaryKey: newGlobalSummaryKey,
      globalTableKey: newGlobalTableKey,
    });
  }

  /**
   * Sets the listing data back to default.
   * @function
   */
  async clearListingData(queryStringWithPage) {
    await this.fetchDefaultListingData();
    const newGlobalResetKey = this.state.globalResetKey + 1;
    let queryString = '';

    if (typeof queryStringWithPage === 'string') {
      queryString += queryStringWithPage;
    }

    const newFilters = parseAndSetSearchFilters({
      itemsPerPage: this.state.defaultItemsPerPage,
      windowLocation: { search: queryString },
      documentsTab: this.props.documentsTab,
    });

    this.setState({
      filters: newFilters,
      globalResetKey: newGlobalResetKey,
    });
  }

  /**
   * Gets the default documents and summary.
   * @function
   */
  async fetchDefaultListingData() {
    this.initialState.windowLocation.search = '';
    this.initialState.filters.itemsPerPage = this.state.defaultItemsPerPage;

    // We don't need account settings again
    const documentsResponse = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.initialState.filters,
      this.initialState.windowLocation
    );

    this.setDocumentsAndTotals(documentsResponse);

    await this.fetchSecondaryData();
  }

  /**
   * Enables/Disables the table loading when a Bulk action with polling is being performed.
   */
  setBulkLoading = () =>
    this.setState((prevState) => ({
      isBulkLoading: !prevState.isBulkLoading,
    }));

  /**
   * When a bulk finishes the process, this callback is called to let the components know that the
   * operation has finished.
   */
  setBulkFinished = () =>
    this.setState((prevState) => ({
      hasBulkFinished: !prevState.hasBulkFinished,
    }));

  /**
   * Fetches documents.
   * @function
   */
  async updateAfterBulk() {
    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const updateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchDocumentsAfterBulk(
      updateCallback,
      searchInformation,
      accountInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Opens the mobile side menu.
   * @param {string} sideContent - the side content to display: filters, options, summary or favorites.
   * @function
   */
  openMobileMenu(sideContent) {
    this.setState({ showMobileMenu: true, mobileSideContent: sideContent });
  }

  /**
   * Closes the mobile side menu.
   * @function
   */
  closeMobileMenu() {
    helpScoutBeaconHelper.changeVisibility(false);
    this.setState({ showMobileMenu: false, mobileSideContent: '' });
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/rendering-elements.html
   * @function
   * @returns {object} React fragment. Read: https://reactjs.org/docs/fragments.html
   */
  render() {
    const {
      filters,
      isLoading,
      isLoadingFirstRequest,
      isBulkLoading,
      hasBulkFinished,
      globalResetKey,
      globalTableKey,
      documents,
      documentsSelected,
      documentsDeselected,
      documentsNumber,
      numberOfDocuments,
      numberOfPages,
      accountSettings,
      defaultFavorites,
      customFavorites,
      favoriteUID,
      filterUpdatedFlag,
      totals,
      clients,
      clientSearchTerm,
      language,
      userId,
      userEmail,
      accountId,
      allDocumentsSelected,
      prevAllDocumentsSelected,
      showMobileMenu,
      mobileSideContent,
    } = this.state;

    return (
      <TableContainer
        className=''
        message={<FormattedMessage id='guides' />}
        searchBox={
          <SearchBox
            key={globalResetKey}
            filters={filters}
            clients={clients || []}
            searchByPlugins={() => {}}
            clientSearchTerm={clientSearchTerm}
            getClientsMatch={this.getClientsMatch}
            getDocumentsByClient={this.getDocumentsByClient}
            accountId={accountId}
            userId={userId}
            accountSettings={accountSettings}
            documentsTab={this.props.documentsTab}
            defaultFavorites={defaultFavorites}
            customFavorites={customFavorites}
            favoriteUID={favoriteUID}
            filterUpdatedFlag={filterUpdatedFlag}
            getDocumentsByTextInput={this.getDocumentsByTextInput}
            getDocumentsByType={this.getDocumentsByType}
            getDocumentsByDate={this.getDocumentsByDate}
            getDocumentsByStatus={this.getDocumentsByStatus}
            getDocumentsByValue={this.getDocumentsByValue}
            updateAndApplyFilter={this.updateAndApplyFilter}
            updateFavoriteUID={this.updateFavoriteUID}
            updateFavoriteFlag={this.updateFavoriteFlag}
            removeFromCustomFavoritesList={this.removeFromCustomFavoritesList}
            addToCustomFavoritesList={this.addToCustomFavoritesList}
            updateCustomFavoritesList={this.updateCustomFavoritesList}
            mobileSideContent={mobileSideContent}
            openMobileMenu={this.openMobileMenu}
            closeMobileMenu={this.closeMobileMenu}
          />
        }
        table={
          <Table
            globalResetKey={globalResetKey}
            globalTableKey={globalTableKey}
            loadingFirstRequest={isLoadingFirstRequest}
            loading={isLoading}
            documents={documents}
            documentsSelected={documentsSelected}
            documentsDeselected={documentsDeselected}
            allDocumentsSelected={allDocumentsSelected}
            prevAllDocumentsSelected={prevAllDocumentsSelected}
            deselectAllDocuments={this.deselectAllDocuments}
            selectAllDocuments={this.selectAllDocuments}
            filters={filters}
            numberOfPages={numberOfPages}
            accountSettings={accountSettings}
            getDocumentsPage={this.getDocumentsPage}
            sortOptions={guidesSortOptions}
            sortDocumentsByPropertyAndOrder={
              this.sortDocumentsByPropertyAndOrder
            }
            setshowValuesWithVat={this.setshowValuesWithVat}
            setItemsPerPage={this.setItemsPerPage}
            getDocumentTotals={this.getUpdatedSelectionInfo}
            resetAllFilters={this.resetAllFilters}
            documentsTab={this.props.documentsTab}
            totals={totals}
            openMobileMenu={this.openMobileMenu}
            mobileSideContent={mobileSideContent}
            closeMobileMenu={this.closeMobileMenu}
          />
        }
        rightMenu={
          <RightMenu
            mobileSideContent={mobileSideContent}
            closeMobileMenu={this.closeMobileMenu}
            documentsTab={this.props.documentsTab}
          />
        }
        mobileMenu={
          <MobileMenu
            showMobileMenu={showMobileMenu}
            closeMobileMenu={this.closeMobileMenu}
            sideContent={mobileSideContent}
          />
        }
        toolbar={
          <ToolBar
            accountId={accountId}
            documents={documents}
            userId={userId}
            userEmail={userEmail}
            language={language}
            filters={filters}
            accountSettings={accountSettings}
            windowLocation={this.state.windowLocation}
            documentsSelected={documentsSelected}
            documentsDeselected={documentsDeselected}
            allDocumentsSelected={allDocumentsSelected}
            prevAllDocumentsSelected={prevAllDocumentsSelected}
            numberOfDocuments={numberOfDocuments}
            documentsNumber={documentsNumber}
            selectionAmount={documentsSelected.size}
            totalDocuments={this.state.totals}
            clearSelection={this.clearSelection}
            selectAllDocuments={this.selectAllDocumentsAndRefreshSelection}
            updateAfterBulk={this.updateAfterBulk}
            setBulkLoading={this.setBulkLoading}
            setBulkFinished={this.setBulkFinished}
            isPerformingBulk={isBulkLoading}
            hasBulkFinished={hasBulkFinished}
            documentsTab={this.props.documentsTab}
          />
        }
      />
    );
  }
}
