import React from 'react';
import { injectIntl } from 'react-intl';
import Input from '../../../../atoms/inputs/Input';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import Span from '../../../../atoms/spans/Span';

export const PreferencesSection = (props) => {
  const { fields, onChangeField, phoneCountryCodes } = props;

  return (
    <>
      <Paragraph className='text-header h4 mb-1'>
        {props.intl.messages['primaryContact']}
      </Paragraph>
      <Span className='color-gray-400'>
        {props.intl.messages['primaryContactSubtitle']}
      </Span>
      <div className='sep-light mt-2' />
      <div className='form-container'>
        <div className='form-row row mx-0'>
          <div className='col-12 px-0'>
            <label className='text-label'>{props.intl.messages['name']}</label>
            <Input
              id='preferred_contact_name'
              type='text'
              controlled
              value={fields?.preferred_contact?.name}
              onChange={(e) =>
                onChangeField('preferred_contact.name', e.target.value)
              }
            />
          </div>

          <div className='col-md-6 col-sm-12 px-0 pe-md-2'>
            <label className='text-label mt-3'>
              {props.intl.messages['email']}
            </label>
            <Input
              id='preferred_contact_email'
              type='text'
              controlled
              value={fields?.preferred_contact?.email}
              onChange={(e) =>
                onChangeField('preferred_contact.email', e.target.value)
              }
            />
            <Paragraph className='text-paragraph --small'>
              {props.intl.messages['preferredEmailMessage']}
            </Paragraph>
          </div>

          <div className='col-md-6 col-sm-12 px-0 ps-md-2'>
            <label className='text-label mt-3'>
              {props.intl.messages['telephone']}
            </label>
            <div className='row mx-0'>
              <div className='col-5 ps-0 pe-2'>
                <SelectWithValues
                  id='preferred_contact_phone_codes_select'
                  keyName='phone_country_id'
                  valueName='phone_code'
                  options={phoneCountryCodes}
                  value={fields?.preferred_contact?.phone_country_id}
                  onChange={(e) =>
                    onChangeField(
                      'preferred_contact.phone_country_id',
                      e.target.value
                    )
                  }
                />
              </div>
              <div className='col-7 ps-2 pe-0'>
                <Input
                  id='preferred_contact_phone_number_input'
                  type='number'
                  placeholder='123456789'
                  controlled
                  value={fields?.preferred_contact?.phone}
                  onChange={(e) =>
                    onChangeField('preferred_contact.phone', e.target.value)
                  }
                  disabled={!fields?.preferred_contact?.phone_country_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(PreferencesSection);
