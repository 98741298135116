import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Menu, { MENU_CATEGORY } from '../../../../organisms/menu/Menu';
import Button from '../../../../atoms/buttons/Button';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import Span from '../../../../atoms/spans/Span';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import { GlobalsHelpers } from '../../../helper/globals';
import { ContactService } from '../../../../../services';
import HandleCallResultModal from '../../../feedback/modal/HandleCallResultModal';

const BillingPreferencesLayout = (props) => {
  const [fields, setFields] = useState({
    language: '',
    payment_mechanism: '',
    decimal_marker: '',
    tax_exemption_code: '',
  });
  const [accountSettings, setAccountSettings] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const settingsResponse = await ContactService.getAccountSettings();
      setAccountSettings(settingsResponse.account);

      let fieldsToParse = [
        'language',
        'payment_mechanism',
        'decimal_marker',
        'tax_exemption_code',
      ];
      let fetchedFields = {};

      fieldsToParse.forEach((property) => {
        fetchedFields[property] = settingsResponse.account[property];
      });

      setFields(fetchedFields);
    }

    fetchData();
  }, []);

  const onChangeField = (key, value) => {
    setFields({
      ...fields,
      [key]: value,
    });
  };

  const isDisabled = () => {
    if (submitting) return true;

    const isAnyFieldEmpty = Object.values(fields).some((field) => field === '');

    const areFieldsDefault = Object.keys(fields).every(
      (key) => fields[key] === accountSettings[key]
    );

    return isAnyFieldEmpty || areFieldsDefault;
  };

  const onSubmit = async () => {
    if (submitting) return;

    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('processing');

    const response = await ContactService.updateAccountSettings(fields);
    setAccountSettings(response.account);
    setSubmitting(false);

    if (!response.error) {
      setCallResponse('companyBillingPreferencesSuccess');
      setCallSucceeded(true);
    } else {
      setCallResponse('companyBillingPreferencesError');
      setCallSucceeded(false);
    }
  };

  return (
    <div id='main-content' className='container --billing-prepreferences'>
      <div className='row mx-0 justify-content-center'>
        <div className='col-lg-9 ps-md-0'>
          <div className='d-flex flex-column w-100'>
            <div className='col-12 px-0'>
              <Paragraph className='text-header h2'>
                {props.intl.messages['companyBillingPreferencesTitle']}
              </Paragraph>

              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {props.intl.messages['companyBillingPreferencesSubtitle']}
                </Span>
              </Paragraph>

              <div className='sep-light mt-2' />
            </div>

            <div className='form-container d-flex flex-wrap w-100 mt-4'>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {props.intl.messages['companyBillingPreferencesLanguage']}
                  </label>
                  <SelectWithValues
                    id='language_id_select'
                    key='language_key_select'
                    valueName='value'
                    options={GlobalsHelpers.getLanguageList(props.intl)}
                    value={fields?.language}
                    onChange={(e) => onChangeField('language', e.target.value)}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {
                      props.intl.messages[
                        'companyBillingPreferencesPaymentMethod'
                      ]
                    }
                  </label>
                  <SelectWithValues
                    id='payment_id_select'
                    key='payment_key_select'
                    valueName='value'
                    options={GlobalsHelpers.getPaymentMechanismList(props.intl)}
                    value={fields?.payment_mechanism}
                    onChange={(e) =>
                      onChangeField('payment_mechanism', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {props.intl.messages['companyBillingPreferencesSeparator']}
                  </label>
                  <SelectWithValues
                    id='decimal_marker_select'
                    key='decimal_marker_select'
                    options={GlobalsHelpers.getDecimalMarkerList(props.intl)}
                    value={fields?.decimal_marker}
                    onChange={(e) => {
                      onChangeField('decimal_marker', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {
                      props.intl.messages[
                        'companyBillingPreferencestaxExemptionReason'
                      ]
                    }
                  </label>
                  <SelectWithValues
                    id='tax_id_select'
                    key='tax_key_select'
                    valueName='value'
                    options={GlobalsHelpers.getTaxExemptionList(props.intl)}
                    value={fields?.tax_exemption_code}
                    onChange={(e) =>
                      onChangeField('tax_exemption_code', e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className='d-flex col-12 px-0 mt-4'>
              <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
                <Button
                  className='button button-primary ms-auto ms-sm-0'
                  id={'save_button'}
                  label={props.intl.messages['save']}
                  onClick={onSubmit}
                  disabled={isDisabled()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 pe-md-0'>
          <Menu {...props} activeCategory={MENU_CATEGORY.PERSONALIZATION} />
        </div>
      </div>

      {isSubmitted && (
        <HandleCallResultModal
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
            window.location.reload();
          }}
          showCloseButton={true}
        />
      )}
    </div>
  );
};

export default injectIntl(BillingPreferencesLayout);
