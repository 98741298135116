import React, { useContext, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import RightMenu from '../../../organisms/containers/contacts/RightMenu';
import MobileMenu from '../../../organisms/containers/mobile/MobileMenu';
import MobileButtons from '../../../organisms/buttons/MobileButtons';
import Anchor from '../../../atoms/anchors/Anchor';
import Span from '../../../atoms/spans/Span';
import { errorKeys } from '../../../../constants/documentCustomization';
import { computeAccountPaymentDaysOption } from '../../../organisms/containers/util/selectsHelper';
import { ALL_INVOICES_BY_CLIENT } from '../../../../constants';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import { formatMoney } from '../../../../formatters/money';
import { AppContext } from '../../../../contexts/AppContext';
import {
  closeMobile,
  documentNumberOfCopies,
  openMobile,
} from '../../helper/documentInformationHelper';
import { useParams } from 'react-router-dom';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import ConfirmationModal from '../../../organisms/modals/ConfirmationModal';
import Button from '../../../atoms/buttons/Button';
import {
  NotificationService,
  NOTIFICATIONS_TYPE,
} from '../../../../services/NotificationService';
import StorageService from '../../../../services/StorageService';
import { STORAGE_KEYS } from '../../../../constants/storage';
import ViewContactMovementBalance from './ViewContactMovementBalance';
import { BalanceMovementService, ContactService } from '../../../../services';
import { SettingService } from '../../../../services/SettingService';

/**
 * @function
 */
const ViewContactPageLayout = (props) => {
  const { appState } = useContext(AppContext);
  const Storage = new StorageService();
  const urlParams = useParams();

  const [showMobileMenu, setOpenMobileMenu] = useState(false);
  const [mobileSideContent, setMobileSideContent] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [balance, setBalance] = useState({});
  const [refreshRegularization, setRefreshRegularization] = useState(false);

  const [requestErrors, setRequestErrors] = useState('');

  const listDocumentCopies = documentNumberOfCopies();

  const getPaymentDays = () => {
    return computeAccountPaymentDaysOption(accountData.payment_days);
  };

  const formatCurrency = (value) => {
    return formatMoney(value, balance.currency?.code || 'EUR', ',');
  };

  const getPhoneCode = (id) => {
    return phoneCountryCodes.length > 0
      ? phoneCountryCodes.filter((codes) => codes.phone_country_id === id)[0]
          ?.phone_code
      : [];
  };

  const getCurrency = (id) => {
    const currency = currencies.length
      ? currencies.filter((currency) => currency.id === id)[0]
      : {};
    return `${currency?.name} (${currency?.symbol})`;
  };

  const handleMobileButtons = (action) => {
    openMobile(setOpenMobileMenu, setMobileSideContent, action);
    setMobileSideContent(action);
  };

  /**
   * Delete contact
   */
  const handleDelete = async () => {
    const response = await ContactService.deleteById(urlParams.client_id);

    if (response && !response.errors) {
      window.location.href = buildPath('v4/contacts');
      return;
    }

    setRequestErrors(errorKeys.deleteError);
  };

  const fetchBalance = async () => {
    const response = await BalanceMovementService.getBalance(
      urlParams.client_id
    );
    if (response.error) {
      setRequestErrors(response.error);
      return;
    }
    setBalance(response);
  };

  const fetchAccount = async () => {
    const response = await ContactService.getById(urlParams.client_id);
    if (response.error) {
      setRequestErrors(response.error);
      return;
    }
    setAccountData(response.client);
  };

  useMemo(async () => {
    const STORAGE_KEY = STORAGE_KEYS.NOTIFICATION_TEMPLATE(appState.userEmail);

    const getConfiguration = async () => {
      const response = await NotificationService.getTemplateByType(
        NOTIFICATIONS_TYPE.EMAIL
      );

      if (!response.error) {
        Storage.setItem(STORAGE_KEY, {
          email: response.notification_configuration,
        });
      }
    };

    if (!Storage.getItem(STORAGE_KEY)) {
      await getConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.userEmail]);

  useEffect(() => {
    const fetchPhoneCodes = async () => {
      const response = await SettingService.getPhoneCodeList();
      if (!response.error) {
        setPhoneCountryCodes(response.phone_calling_codes);
      }
    };

    const fetchCurrencies = async () => {
      const response = await SettingService.getCurrencyList();
      if (!response.error) {
        setCurrencies(response.currencies);
      }
    };

    fetchCurrencies();
    fetchPhoneCodes();
    fetchAccount();
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='main-content' className='container --contacts'>
      <div className='row mx-0 justify-content-center'>
        <div className='col-12 px-lg-0 content-block page-header-block'>
          <div className='text-header h3 --secondary'>
            {props.intl.messages['contacts']}
          </div>

          <MobileButtons
            openMobileMenu={(sideContent) =>
              openMobile(setOpenMobileMenu, setMobileSideContent, sideContent)
            }
          />
        </div>

        <div className='col-lg-9 ps-lg-0'>
          <div className='page-sub-header-block'>
            <div className='text-header h2 mb-auto'>
              {accountData.name || ''}
            </div>
          </div>

          {requestErrors.length > 0 && (
            <RequestHandelingAlert error={requestErrors} />
          )}
          <div className='row mx-0 mt-2 --mobile-actions'>
            <div className='col-12 px-0'>
              <Button
                id='open_mobile_actions'
                className='button button-outline --gray --medium'
                label='Ações do Contacto'
                onClick={() => {
                  handleMobileButtons('mobileActions');
                }}
              />

              <Button
                id='open_mobile_documents'
                className='button button-outline --gray --medium ms-4'
                label='Criar Documentos'
                onClick={() => {
                  handleMobileButtons('mobileButtons');
                }}
              />
            </div>
          </div>

          <div className='col-12 mt-2 text-align-right'>
            <Anchor
              href={buildPath(ALL_INVOICES_BY_CLIENT(appState.clientId))}
              className='color-brand-primary bold'
            >
              {props.intl.messages['seeContactDocuments']}
            </Anchor>
          </div>

          <div className='block-grid white-grid'>
            <div className='row grid-block two-cols-container'>
              <div className='col-md-6'>
                <div className='title'>{props.intl.messages['balance']}</div>
                <div className='value color-brand-primary'>
                  {balance?.balance
                    ? formatCurrency(balance.balance?.with_vat)
                    : '0,00€ '}
                  <Span className='value-description'>
                    {props.intl.messages['w/Vat']}
                  </Span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='title'>{props.intl.messages['overdue']}</div>
                <div className='value color-error'>
                  {balance?.overdue_balance
                    ? formatCurrency(balance.overdue_balance?.with_vat)
                    : '0,00€ '}
                  <Span className='value-description'>
                    {props.intl.messages['w/Vat']}
                  </Span>
                </div>
              </div>
            </div>
            <div className='row grid-block multiple-content-container'>
              <div className='col-12 text-header h4'>
                {props.intl.messages['details']}
              </div>
              <div className='col-sm-6'>
                <ul className='list-of-data'>
                  <li>
                    <span className='label'>
                      {props.intl.messages['name']}:
                    </span>
                    <span data-testid='name_text'>
                      {accountData?.name || ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>{props.intl.messages['nif']}:</span>
                    <span data-testid='vat_text'>{accountData?.fiscal_id}</span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['code']}:
                    </span>
                    <span data-testid='code_text'>
                      {accountData?.code || ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['address']}:
                    </span>
                    <span data-testid='address_text'>
                      {accountData?.address}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['postalCode']}:
                    </span>
                    <span data-testid='postalCode_text'>
                      {accountData?.postal_code}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['city']}:
                    </span>
                    <span data-testid='city_text'>{accountData?.city}</span>
                  </li>
                </ul>
              </div>
              <div className='col-sm-6'>
                <ul className='list-of-data'>
                  <li>
                    <span className='label'>
                      {props.intl.messages['country']}:
                    </span>
                    <span data-testid='country_text'>
                      {accountData?.country}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['email']}:
                    </span>
                    <span data-testid='email_text'>{accountData?.email}</span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['website']}:
                    </span>
                    <span data-testid='website_text'>
                      {accountData?.website}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['telephone']}:
                    </span>
                    <span data-testid='phone_text'>
                      {accountData?.phone_country_id && accountData?.phone
                        ? `${getPhoneCode(accountData?.phone_country_id)} ${
                            accountData?.phone
                          }`
                        : ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['cellphone']}:
                    </span>
                    <span data-testid='cellphone_text'>
                      {accountData?.mobile_country_id && accountData?.mobile
                        ? `${getPhoneCode(accountData?.mobile_country_id)} ${
                            accountData?.mobile
                          }`
                        : ''}
                    </span>
                  </li>
                </ul>
              </div>
              <div className='sep-light' />
              <div className='col-12 text-header h4'>
                {props.intl.messages['primaryContact']}
              </div>
              <div className='col-12'>
                <ul className='list-of-data'>
                  <li>
                    <span className='label'>
                      {props.intl.messages['name']}:
                    </span>

                    <span data-testid='preferences_name_text'>
                      {accountData.preferred_contact?.name || ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['telephone']}:
                    </span>
                    <span data-testid='preferences_phone_text'>
                      {accountData.preferred_contact?.phone &&
                      accountData.preferred_contact?.phone_country_id
                        ? `${getPhoneCode(
                            accountData.preferred_contact?.phone_country_id
                          )} ${accountData.preferred_contact?.phone}`
                        : ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['email']}:
                    </span>
                    <span data-testid='preferences_email_text'>
                      {accountData.preferred_contact?.email || ''}
                    </span>
                  </li>
                </ul>
              </div>
              <div className='sep-light' />
              <div className='col-12 text-header h4'>
                {props.intl.messages['billingPreferences']}
              </div>
              <div className='col-sm-6'>
                <ul className='list-of-data'>
                  <li>
                    <span className='label'>
                      {props.intl.messages['numCopies']}:
                    </span>
                    <span data-testid='billing_numCopies_text'>
                      {props.intl.messages[
                        listDocumentCopies[accountData?.document_copies]
                      ] || ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['paymentMechanism']}:
                    </span>
                    <span data-testid='billing_paymentMechanism_text'>
                      {props.intl.messages[accountData?.payment_mechanism] ||
                        ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['language']}:
                    </span>
                    <span data-testid='billing_language_text'>
                      {props.intl.messages[accountData?.language] || ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['observations']}:
                    </span>
                    <span data-testid='billing_observations_text'>
                      {accountData?.observations || ''}
                    </span>
                  </li>
                </ul>
              </div>
              <div className='col-sm-6'>
                <ul className='list-of-data'>
                  <li>
                    <span className='label'>
                      {props.intl.messages['paymentDays']}:
                    </span>
                    <span data-testid='billing_paymentDays_text'>
                      {props.intl.messages[getPaymentDays()] || ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['taxExemptionReason']}:
                    </span>
                    <span data-testid='billing_taxExemptionReason_text'>
                      {props.intl.messages[accountData?.tax_exemption_code] ||
                        ''}
                    </span>
                  </li>
                  <li>
                    <span className='label'>
                      {props.intl.messages['currency']}:
                    </span>
                    <span data-testid='billing_currency_text'>
                      {accountData?.currency_id
                        ? `${getCurrency(accountData?.currency_id)}`
                        : ''}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <ViewContactMovementBalance
              documentsTab={props.documentsTab}
              formatCurrency={formatCurrency}
              balance={balance}
              clientId={urlParams.client_id}
              reloadList={refreshRegularization}
              reloadBalance={fetchBalance}
            />
          </div>
        </div>
        <div className='col-lg-3 pe-lg-0'>
          <RightMenu
            clientId={urlParams.client_id}
            contactName={
              accountData.preferred_contact?.name || accountData?.name
            }
            contactEmail={accountData.preferred_contact?.email || ''}
            openAccountLink={accountData.open_account_link || ''}
            handleDelete={() => setIsDeleted(true)}
            mobileSideContent={mobileSideContent}
            accountSettings={{
              currency_symbol: balance.currency?.symbol || '€',
            }}
            remainingBalance={{
              remaining: balance.remaining_balance
                ? formatCurrency(balance.remaining_balance.value)
                : '0,00€ ',
              migrated: balance?.initial_balance
                ? formatCurrency(balance.initial_balance?.value)
                : '0,00€ ',
            }}
            refreshBalance={fetchBalance}
            refreshRegularization={() =>
              setRefreshRegularization((state) => !state)
            }
            refreshClient={fetchAccount}
          />
        </div>
      </div>

      {isDeleted && (
        <ConfirmationModal
          showModal={isDeleted}
          modalMessageId={'deleteMessage'}
          confirmButtonId={'confirmAction'}
          onConfirmationFunction={handleDelete}
          changeModalVisibility={() => setIsDeleted(false)}
        />
      )}

      <MobileMenu
        showMobileMenu={showMobileMenu}
        closeMobileMenu={() =>
          closeMobile(setOpenMobileMenu, setMobileSideContent)
        }
        sideContent={mobileSideContent}
      />
    </div>
  );
};

export default injectIntl(ViewContactPageLayout);
