import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Button from '../../../atoms/buttons/Button';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel';
import { ExportSaftHelper } from './helper';
import { SAFTService } from '../../../../services/SAFTService';
import HandleCallResultModal from '../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import moment from 'moment';

const ExportSaftLayout = (props) => {
  const defaultFormData = ExportSaftHelper.getInitialState(props.intl);

  const [form, setForm] = useState(defaultFormData);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);

  useEffect(() => {
    console.log(moment().month());
  }, []);

  const onFormChange = (e, section) => {
    const value = e.target.value;

    console.log(value);

    let newForm = structuredClone(form);
    if (section === 'type') {
      newForm.saftType = value;
      newForm.period = ExportSaftHelper.PERIOD.MONTH;
    } else if (section === 'period') {
      newForm.period = value;
    } else if (section === 'month') {
      newForm.month = {
        key: `exportSAFTMonth${parseInt(value)}`,
        value: parseInt(value),
      };
      newForm.day = 1;
    } else if (section === 'week') {
      newForm.week = value;
    } else if (section === 'day') {
      newForm.day = parseInt(value);
    }

    setForm(newForm);
  };

  const populateDays = () => {
    const monthKey = ExportSaftHelper.getMonths(props.intl).find(
      (el) => el.value === form?.month.value
    );

    if (monthKey) {
      const monthNum = parseInt(monthKey.key.split('exportSAFTMonth')[1]);
      return ExportSaftHelper.getDaysInMonth(form?.year, monthNum);
    }
  };

  const populateWeeks = () => {
    const monthKey = ExportSaftHelper.getMonths(props.intl).find(
      (el) => el.value === form?.month.value
    );

    if (monthKey) {
      const monthNum = parseInt(monthKey.value);
      return ExportSaftHelper.getWeeksInMonth(
        props.intl,
        form?.year,
        monthNum,
        props.intl.messages[monthKey.key]
      );
    }
  };

  const onSubmit = async () => {
    if (submitting) return;

    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('exportSAFTProcessing');
    const data = structuredClone(form);

    data.year = data.year.toString();

    if (data.saftType === 'guides') {
      delete data.week;
      delete data.day;
    }

    if (data.period === 'year') {
      delete data.month;
      delete data.day;
      delete data.week;
    } else if (data.period === 'month') {
      data.month = (data.month.value + 1).toString();
      delete data.day;
      delete data.week;
    } else if (data.period === 'week') {
      data.month = (data.month.value + 1).toString();
      data.day = data.week;
      delete data.week;
    } else if (data.period === 'day') {
      data.month = (data.month.value + 1).toString();
      delete data.week;
    }

    console.log('Form data after:', data);

    // Call API
    const params = new URLSearchParams(data).toString();

    const response = await SAFTService.exportSAFT(params);
    setSubmitting(false);

    if (!response.error) {
      // Handle api response
      const sanitizedResponse = DOMPurify.sanitize(response.message);
      const parsedResponse = parse(sanitizedResponse);
      setCallResponse(parsedResponse);
      setCallSucceeded(true);
    } else {
      // Handle api response
      const sanitizedResponse = DOMPurify.sanitize(response.error);
      const parsedResponse = parse(sanitizedResponse);
      setCallResponse(parsedResponse);
      setCallSucceeded(false);
    }
  };

  return (
    <div id='main-content' className='container --export-saft'>
      <div className='d-flex flex-wrap w-100'>
        <div className='col-12 col-lg-9 px-0'>
          <div className='d-flex flex-column w-100'>
            <div className='col-lg-10 px-0'>
              <Paragraph className='text-header h2'>
                {props.intl.messages['exportSAFTTitle']}
              </Paragraph>

              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {props.intl.messages['exportSAFTDescription']}
                </Span>
              </Paragraph>
            </div>

            <div className='form-container d-flex flex-column w-100 mt-5'>
              <div className='d-flex flex-column w-100'>
                <label className='text-label mb-3'>
                  {props.intl.messages['exportSAFTDocs']}
                </label>
                <div className='d-flex w-100'>
                  <div className='option-container'>
                    <RadioButtonLabel
                      id='invoice_budget'
                      name='saftType'
                      className='checkbox round'
                      value={'all'}
                      checked={form?.saftType === 'all'}
                      onChange={(e) => onFormChange(e, 'type')}
                    >
                      {'exportSAFTInvoicesAndQuotes'}
                    </RadioButtonLabel>
                  </div>

                  <div className='option-container ms-3'>
                    <RadioButtonLabel
                      id='notes'
                      name='saftType'
                      className='checkbox round'
                      value={'guides'}
                      checked={form?.saftType === 'guides'}
                      onChange={(e) => onFormChange(e, 'type')}
                    >
                      {'exportSAFTNotes'}
                    </RadioButtonLabel>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-column w-100 mt-5'>
                <label className='text-label mb-3'>
                  {props.intl.messages['exportSAFTPeriod']}
                </label>
                <div className='d-flex w-100'>
                  <div className='option-container'>
                    <RadioButtonLabel
                      id='period_annual'
                      name='period'
                      className='checkbox round'
                      value={'year'}
                      checked={form.period === 'year'}
                      onChange={(e) => onFormChange(e, 'period')}
                    >
                      {'exportSAFTPeriodAnnual'}
                    </RadioButtonLabel>
                  </div>

                  <div className='option-container ms-3'>
                    <RadioButtonLabel
                      id='period_monthly'
                      name='period'
                      className='checkbox round'
                      value={'month'}
                      checked={form.period === 'month'}
                      onChange={(e) => onFormChange(e, 'period')}
                    >
                      {'exportSAFTPeriodMonthly'}
                    </RadioButtonLabel>
                  </div>

                  {form?.saftType === 'all' && (
                    <div className='option-container ms-3'>
                      <RadioButtonLabel
                        id='period_weekly'
                        name='period'
                        className='checkbox round'
                        value={'week'}
                        checked={form.period === 'week'}
                        onChange={(e) => onFormChange(e, 'period')}
                      >
                        {'exportSAFTPeriodWeekly'}
                      </RadioButtonLabel>
                    </div>
                  )}

                  {form?.saftType === 'all' && (
                    <div className='option-container ms-3'>
                      <RadioButtonLabel
                        id='period_daily'
                        name='period'
                        className='checkbox round'
                        value={'day'}
                        checked={form.period === 'day'}
                        onChange={(e) => onFormChange(e, 'period')}
                      >
                        {'exportSAFTPeriodDaily'}
                      </RadioButtonLabel>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column flex-sm-row col-lg-10 px-0 mt-5 period-selector-container'>
                <div className='col period-selector'>
                  <label className='text-label mb-3'>
                    {props.intl.messages['exportSAFTYearLabel']}
                  </label>
                  <SelectWithValues
                    id='year'
                    key='year'
                    options={[{ key: 2025, value: 2025 }]}
                    value={2025}
                    onChange={() => {}}
                  />
                </div>

                {((form?.saftType === 'all' && form?.period !== 'year') ||
                  (form?.saftType === 'guides' && form?.period !== 'year')) && (
                  <div className='col period-selector'>
                    <label className='text-label mb-3'>
                      {props.intl.messages['exportSAFTMonthLabel']}
                    </label>
                    <SelectWithValues
                      id='month'
                      key='month'
                      options={ExportSaftHelper.getMonths(props.intl)}
                      value={form.month.value}
                      onChange={(e) => onFormChange(e, 'month')}
                    />
                  </div>
                )}

                {form?.saftType === 'all' && form?.period === 'week' && (
                  <div className='col period-selector'>
                    <label className='text-label mb-3'>
                      {props.intl.messages['exportSAFTWeekLabel']}
                    </label>
                    <SelectWithValues
                      id='week'
                      key='week'
                      options={populateWeeks()}
                      value={form?.week}
                      onChange={(e) => onFormChange(e, 'week')}
                    />
                  </div>
                )}

                {form?.saftType === 'all' && form?.period === 'day' && (
                  <div className='col period-selector'>
                    <label className='text-label mb-3'>
                      {props.intl.messages['exportSAFTDailyLabel']}
                    </label>
                    <SelectWithValues
                      id='day'
                      key='day'
                      options={populateDays()}
                      value={form?.day}
                      onChange={(e) => onFormChange(e, 'day')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex col-12 col-lg-10 px-0 mt-4'>
              <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
                <div className='d-flex saft-version me-4 mb-4 my-sm-auto'>
                  {form?.saftType === 'all' && (
                    <span className='text-paragraph'>
                      <FormattedMessage
                        id='exportSAFTInvoicesVersion'
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </span>
                  )}

                  {form?.saftType === 'guides' && (
                    <span className='text-paragraph'>
                      <FormattedMessage
                        id='exportSAFTNotesVersion'
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </span>
                  )}
                </div>

                <Button
                  className='button button-primary ms-auto ms-sm-0'
                  id={'save_button'}
                  label={props.intl.messages['exportSAFTButton']}
                  onClick={onSubmit}
                  disabled={submitting}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-3 px-0 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.AT_COMUNICATION} />
        </div>
      </div>

      {isSubmitted && (
        <HandleCallResultModal
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton={true}
        />
      )}
    </div>
  );
};

export default injectIntl(ExportSaftLayout);
