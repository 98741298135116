import React from 'react';
import { injectIntl } from 'react-intl';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import { RightMenuHelper } from './helper';
import Button from '../../../atoms/buttons/Button';
import Icon from '../../../atoms/icons/Icon';

const RightMenu = (props) => {
  const { id, info, mobileContent, clearMobileContent } = props;
  const showMobileMenu = RightMenuHelper.openMobileSideContent(mobileContent);

  if (showMobileMenu) {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div
      className={`${
        showMobileMenu
          ? 'mobile-side-container ' + showMobileMenu
          : 'container px-0'
      }`}
    >
      <div className={`${showMobileMenu ? 'mobile-side-header' : 'd-none'}`}>
        <div className='header-text h4'>{props.intl.messages['options']}</div>
        <Button
          id={`close-menu-${id}`}
          className='close-button'
          onClick={() => {
            RightMenuHelper.closeMobileSideContent(clearMobileContent);
          }}
        >
          <Icon className={'icon fas fa-times'} />
        </Button>
      </div>

      {/* Mobile Menu: add class 'open' */}
      <div
        className={`buttons-block mobile-side-content p-0 pt-2 pt-lg-0 ${showMobileMenu}`}
      >
        <div className='row mx-0 mt-0 content-block dropdown-button-block'>
          {props.children}

          {(info && (
            <div className='row mx-0 px-0 content-block information-block'>
              {/* Reports Dropdown */}
              {info?.reports && (
                <InformativeDropdown
                  label='reports'
                  dropdownType='help-info-panel'
                  iconClassName='icon fas fa-folder'
                >
                  {RightMenuHelper.mountReportsInfo(info.reports)}
                </InformativeDropdown>
              )}

              {/* Help Information */}
              {info?.help && (
                <InformativeDropdown
                  label='help'
                  dropdownType='help-info-panel'
                  iconClassName='icon fas fa-info-circle'
                  isVisible
                >
                  {RightMenuHelper.mountHelpInfo(info.help)}
                </InformativeDropdown>
              )}
            </div>
          )) || <></>}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(RightMenu);
