import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Button from '../../../atoms/buttons/Button';
import ProviderCardSkeleton from './_skeleton/ProviderCardSkeleton';
import Alert from '../../../molecules/alerts/Alert';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';
import StatusBadge from '../../../atoms/badges/StatusBadge';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import { AccountFiscalService } from '../../../../services/AccountFiscalService';
import Anchor from '../../../atoms/anchors/Anchor';
import { INFO_CASHIER_VAT } from '../../../../constants/url';

/**
 * Enum for IVA key
 * @enum {string}
 * @readonly
 */
export const IVA_KEYS = {
  GENERAL: 'general',
  BOX: 'box',
};

const ixListOfRegimes = [
  {
    key: IVA_KEYS.GENERAL,
    title: 'regimeGlobalTitle',
    description: () => (
      <FormattedMessage
        id={'regimeGlobalDescription'}
        key={'client-alert'}
        values={{
          a: (chunks) => (
            <Anchor
              className='d-block bold color-brand-primary m-1'
              href={INFO_CASHIER_VAT}
              target='_blank'
              key={'client-alert'}
              pathKey={'client-alert'}
            >
              {chunks}
            </Anchor>
          ),
        }}
      />
    ),
    isActive: true,
  },
  {
    key: IVA_KEYS.BOX,
    title: 'regimeBoxTitle',
    description: () => (
      <FormattedMessage
        id={'regimeBoxDescription'}
        key={'client-alert'}
        values={{
          a: (chunks) => (
            <Anchor
              className='d-block bold color-brand-primary m-1'
              href={INFO_CASHIER_VAT}
              target='_blank'
              key={'client-alert'}
              pathKey={'client-alert'}
            >
              {chunks}
            </Anchor>
          ),
        }}
      />
    ),
    checkboxLabel: 'regimeBoxCheckLabel',
    isActive: false,
  },
];

export const ManageIVALayout = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState({ general: true, box: false });
  const [activeIva, setActiveIva] = useState(IVA_KEYS.GENERAL);
  const [hasErrors, setHasErrors] = useState('');

  /**
   * Update form values
   *
   * @param {string} name - column name
   * @param {string} value
   */
  const handleFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const changeRegimeConfiguration = async () => {
    setIsLoading(true);

    const response = AccountFiscalService.changeAccountVAT({
      account: {
        iva_caixa: form.box,
      },
    });

    if (!response.error) {
      setHasErrors('');
      setActiveIva(form.box ? IVA_KEYS.BOX : IVA_KEYS.GENERAL);

      setTimeout(() => {
        setIsLoading(false);
      }, 800);
      return;
    }

    setHasErrors('test');
    setIsLoading(false);
  };

  const mountHeader = (regime) => {
    return (
      <Span className='d-flex'>
        <Paragraph className='text-header h4'>
          {props.intl.messages[regime.title]}
          &nbsp;
        </Paragraph>
        {regime.key === activeIva && (
          <StatusBadge status='active' label='regimeIVAActive'></StatusBadge>
        )}
      </Span>
    );
  };

  useEffect(() => {
    const fetchCurrentIVA = async () => {
      setIsLoading(true);
      const response = await AccountFiscalService.getAccountSettings();

      if (!response.error) {
        setActiveIva(
          response?.account?.iva_caixa ? IVA_KEYS.BOX : IVA_KEYS.GENERAL
        );

        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      }
    };

    fetchCurrentIVA();
  }, []);

  return (
    <div id='main-content' className='container --ds-page'>
      <div className='row justify-content-center mx-0'>
        {/* Left Content */}
        <div className='col-lg-9 ps-0'>
          {/* Header */}
          <div className='col-12 px-0 title-block page-header-block'>
            <div className='col-12 px-0'>
              <div className='text-header h2'>
                <FormattedMessage id='regimeIVATitle' />
              </div>
              <div className='text-paragraph mt-2'>
                <FormattedMessage id='regimeIVASubtitle' />
              </div>
            </div>
          </div>

          {hasErrors && (
            <div className='mb-3 --ds-modals px-0'>
              <Alert
                id={'logo_error_alert'}
                iconClassName='fas fa-exclamation-circle'
                alertType='alert-error --icon --small'
              >
                {props.intl.messages['fetchError']}
              </Alert>
            </div>
          )}

          {isLoading && <ProviderCardSkeleton />}

          {!isLoading && (
            <div className='col-lg-12 col-md-11 --ds-provider px-0'>
              <div className='row mx-0 align-items-center pb-4'>
                {ixListOfRegimes.map((regime, index) => (
                  <div className='col-lg-12 px-4 pb-0 pt-4'>
                    <div className='col-12 px-0'>
                      {regime?.description && (
                        <>
                          {mountHeader(regime)}

                          <Paragraph className='text-paragraph'>
                            {regime.description()}
                          </Paragraph>
                        </>
                      )}
                    </div>

                    <div className='row mx-0 d-flex justify-content-end align-items-center'>
                      {regime.checkboxLabel && regime.key !== activeIva && (
                        <div className='col-md-8 col-12 px-0'>
                          <CheckBoxLabel
                            value={`select-regime-${index}`}
                            className='checkbox'
                            onChange={(e) =>
                              handleFields(regime.key, e.target.checked)
                            }
                            checked={form[regime.key]}
                          >
                            <Span className='text-paragraph --small'>
                              {props.intl.messages[regime.checkboxLabel]}
                            </Span>
                          </CheckBoxLabel>
                        </div>
                      )}

                      {!regime?.description && (
                        <div
                          className={`col-md-${
                            regime.key === activeIva ? '12' : '8'
                          } col-12 px-0`}
                        >
                          {mountHeader(regime)}
                        </div>
                      )}

                      {regime.key !== activeIva && (
                        <div className='col-md-4 col-12 px-0'>
                          <Button
                            className='button button-outline d-block'
                            disabled={!form[regime.key]}
                            onClick={() =>
                              changeRegimeConfiguration(regime.key)
                            }
                          >
                            {props.intl.messages['regimeButtonLabel']}
                          </Button>
                        </div>
                      )}
                    </div>
                    {index < ixListOfRegimes.length - 1 && (
                      <div className='sep-light mt-3' />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Right Content */}
        <div className='col-lg-3 pe-0'>
          <Menu {...props} activeCategory={MENU_CATEGORY.AT_COMUNICATION} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ManageIVALayout);
