import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';
import SequenceRegistrationDetailsModal from '../../organisms/modals/sequences/SequenceRegistrationDetailsModal';
import {
  registrationClassName,
  sequenceStatusName,
  registerStatusName,
  isCollectionInactive,
} from '../../organisms/modals/sequences/util/states.js';
import { DEMO_ACCOUNT_SEQUENCE } from '../../../constants';
// import { ReactComponent as IxLoading } from '../../../assets/imgs/ix-loading.svg';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders data about a sequences collection.
 */
const RowSequenceRegistration = (props) => {
  const { serie_name, status, is_default, register_status, register_type } =
    props.sequence;

  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isLoading] = useState(props.isLoading);

  /**
   * Considering the sequences collection JSON, it creates the content about its status.
   * @function
   * @param {object} sequences_collection - JSON object with a sequences collection properties.
   * @returns {object} returns childs.
   */
  const addCollectionStatus = () => {
    let statusName, className;

    if (isCollectionInactive(register_status, serie_name)) {
      statusName = sequenceStatusName('inactive');
      className = 'inactive';
    } else if (serie_name === DEMO_ACCOUNT_SEQUENCE) {
      className = 'final';
      statusName = 'active';
    } else {
      className = status === 'active' ? 'final' : status;
      statusName = sequenceStatusName(status);
    }

    return (
      <div className='cell align-middle'>
        <div className={`status-label bold status-color-${className}`}>
          <Icon className='icon fas fa-circle' />
          <Span>{props.intl.messages[statusName]}</Span>
        </div>
      </div>
    );
  };

  /**
   * Considering the sequences_collection JSON, it creates the content about its name.
   * @function
   * @param {object} sequences_collection - JSON object with a sequences collection properties.
   * @returns {object} returns childs.
   */
  const addCollectionName = () => (
    <div className='cell align-middle'>
      <div className='bold h4 text-header'>
        {serie_name}
        {is_default && (
          <Span className='card-badge' label={props.intl.messages['default']} />
        )}
      </div>
    </div>
  );

  /**
   * Considering the sequences_collection JSON, it creates the content about its registry status.
   * @function
   * @param {object} sequences_collection - JSON object with a sequences collection properties.
   * @returns {object} returns childs.
   */
  const addCollectionRegisterStatus = () => {
    const registrationClass = registrationClassName(register_status);
    const registerStatus = registerStatusName(register_status);

    return (
      <div className='cell'>
        <Span
          className='d-block card-label'
          label={props.intl.messages['registration'] + ':'}
        />
        <div
          className={`registration-label uppercase status-color-${registrationClass}`}
        >
          <Icon className={`icon fas fa-check-circle`} />
          <Icon className={`icon fas fa-exclamation-circle`} />
          <Icon className={`icon fas fa-times-circle`} />
          <Icon className={`icon fas fa-ban`} />
          {props.intl.messages[registerStatus]}
        </div>
      </div>
    );
  };

  /**
   * Considering the sequences_collection JSON, it creates the content about its regsitry type.
   * @function
   * @param {object} sequences_collection - JSON object with a sequences collection properties.
   * @returns {object} returns childs.
   */
  const addCollectionRegisterType = () => {
    return (
      <div className='cell'>
        <Span
          className='d-block card-label'
          label={props.intl.messages['type'] + ':'}
        />
        <div className='uppercase date text-paragraph --small'>
          {props.intl.messages[register_type] || '-'}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`card --sequence`}
        onClick={() => setIsModalVisible((prevState) => !prevState)}
      >
        <div className='mobile-left'>
          {addCollectionStatus()}
          {addCollectionName()}
        </div>
        <div className='mobile-right'>
          {addCollectionRegisterStatus()}
          {addCollectionRegisterType()}
          <div className='cell action align-middle mobile-visible'>
            <div className='button button-icon-self vertical-sep'>
              <Icon className='icon fas fa-caret-right color-gray-base' />
            </div>
          </div>
        </div>
        {/* {isLoading &&
          <div className='loading loading-tables'>
            <IxLoading />
          </div>
        } */}
      </div>
      {isModalVisible && (
        <SequenceRegistrationDetailsModal
          changeModalVisibility={() =>
            setIsModalVisible((prevState) => !prevState)
          }
          sequence={props.sequence}
          accountId={props.accountId}
          getListing={props.getListing}
          updateListing={props.updateListing}
          credentialsInformation={props.credentialsInformation}
        />
      )}
    </>
  );
};

RowSequenceRegistration.propTypes = {
  sequences: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(RowSequenceRegistration);
