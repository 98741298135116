import React, { useContext, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { errorKeys } from '../../../../constants/documentCustomization';
import { AppContext } from '../../../../contexts/AppContext';
import { ContactService } from '../../../../services/ContactService';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import RightMenu from '../../../organisms/containers/contacts/RightMenu';
import { StateHelper } from '../../helper/state';
import SuccessModal from '../../feedback/modal/SuccessModal';
import { buildOptionsFromCollection } from '../../util/api/adapterHelper';
import { Helper } from './helper';
import BillingSection from './sections/BillingSection';
import DetailsSection from './sections/DetailsSection';
import PreferencesSection from './sections/PreferencesSection';
import Button from '../../../atoms/buttons/Button';

const EditContactPageLayout = (props) => {
  const { appState } = useContext(AppContext);
  const history = useHistory();
  const [fields, setFields] = useState({});
  const [checkBoxState, setCheckBoxState] = useState(true);
  const urlParams = useParams();

  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSendWithSuccess, setIsSendWithSuccess] = useState(true);
  const [requestErrors, setRequestErrors] = useState('');

  const [defaultAccountSettings, setDefaultAccountSettings] = useState({});

  const isAnExternalPage = () => {
    return props.documentsTab === 'EditExternalClient';
  };

  const isCreateContactPage = () => {
    return props.documentsTab === 'CreateContact';
  };

  const onCancelClick = () => {
    history.goBack();
  };

  const showFeedback = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSendWithSuccess(false);
    }, 500);
  };

  /**
   * Call service to create or update contact
   *
   * @async
   * @returns {void}
   */
  const updateContactInformation = async () => {
    if (isRequiredFieldsFilled()) {
      if (checkBoxState) {
        fields.tax_exemption_code = null;
        fields.payment_mechanism = null;
        fields.currency_id = null;
        fields.language = null;
      }

      const formData = Helper.formatRequestPayload(fields, countries);

      const createOrUpdate = async () => {
        return isCreateContactPage()
          ? await ContactService.create(formData)
          : await ContactService.updateById(urlParams.client_id, formData);
      };

      const response = await createOrUpdate();

      if (response.error) {
        setRequestErrors(response.error);
        return;
      }

      showFeedback();
    }
  };

  const isRequiredFieldsFilled = () => {
    return fields.name && fields.name !== '';
  };

  const onChangeField = (key, value) => {
    setFields(Helper.updateObject(fields, { key, value }));
  };

  const onChangeCheckBox = () => {
    setCheckBoxState(!checkBoxState);
  };

  useMemo(() => {
    if (checkBoxState) {
      const updateFields = {
        tax_exemption_code: defaultAccountSettings.tax_exemption_code,
        payment_mechanism: defaultAccountSettings.payment_mechanism,
        currency_id: defaultAccountSettings.currency_id,
        language: defaultAccountSettings.language,
      };

      setFields((prevFields) => {
        return { ...prevFields, ...updateFields };
      });
    }
  }, [checkBoxState, defaultAccountSettings]);

  useMemo(async () => {
    setPhoneCountryCodes(
      await StateHelper.fetchPhoneCountries(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )
    );

    setCountries(
      await StateHelper.fetchCountries(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )
    );

    if (!isCreateContactPage()) {
      const response = await ContactService.getById(urlParams.client_id);
      if (response.error) {
        setRequestErrors(response.error);
        return;
      }
      setFields(response.client);

      const billingFields = [
        'tax_exemption_code',
        'payment_mechanism',
        'payment_days',
        'currency_id',
        'language',
        'document_copies',
        'observations',
      ];

      Object.keys(response.client).forEach((key) => {
        if (billingFields.includes(key) && response.client[key] !== null) {
          setCheckBoxState(false);
        }
      });
    }

    const settingsResponse = await ContactService.getAccountSettings();
    setDefaultAccountSettings(settingsResponse.account);

    const currenciesResponse =
      (await StateHelper.fetchCurrencies(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )) || [];
    setCurrencies(buildOptionsFromCollection('name', 'id', currenciesResponse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.accountId, urlParams.client_id]);

  const successModalMessage = isCreateContactPage()
    ? 'successMessageCreateModal'
    : 'successMessageEditModal';

  return (
    <div id='main-content' className='container --contacts'>
      <div className='row justify-content-center mx-0'>
        <div className='col-lg-9 ps-0'>
          <div className='block-grid'>
            <div className='row grid-block two-cols-container mx-0'></div>
            <div className='row grid-block form-container mx-0'>
              {/* Header */}
              <div className='col-12 grid-block page-header-block px-0'>
                <div className='text-header h2 text-align-left'>
                  {
                    props.intl.messages[
                      Helper.getTitleOfHeader(props.documentsTab)
                    ]
                  }
                </div>
                {isAnExternalPage() && (
                  <Paragraph>
                    {props.intl.messages['fiscalInformation']}
                  </Paragraph>
                )}

                {requestErrors.length > 0 && (
                  <RequestHandelingAlert error={requestErrors} />
                )}
              </div>

              <div className='col-12 px-0'>
                <DetailsSection
                  fields={fields}
                  phoneCountryCodes={phoneCountryCodes}
                  countries={countries}
                  onChangeField={onChangeField}
                  isExternalPage={isAnExternalPage()}
                />
              </div>

              {!isAnExternalPage() && (
                <>
                  <div className='col-12 px-0 mt-5 px-0'>
                    <PreferencesSection
                      fields={fields}
                      phoneCountryCodes={phoneCountryCodes}
                      onChangeField={onChangeField}
                    />
                  </div>

                  <div className='col-12 mt-3 px-0'>
                    <Paragraph className='text-header h4 mb-1'>
                      {props.intl.messages['billingPreferences']}
                    </Paragraph>
                    <Span className='color-gray-400'>
                      {props.intl.messages['billingPreferencesSubtitle']}
                    </Span>
                    <div className='sep-light mt-2' />
                  </div>

                  <div className='col-12 px-0'>
                    <BillingSection
                      fields={fields}
                      isCreateContactPage={isCreateContactPage()}
                      currencies={currencies}
                      onChangeField={onChangeField}
                      checkBoxState={checkBoxState}
                      onChangeCheckBox={onChangeCheckBox}
                    />
                  </div>
                </>
              )}

              <div className='d-flex justify-content-end px-0'>
                <Button
                  id={'cancel_button'}
                  className='button button-secondary me-2'
                  onClick={isAnExternalPage() ? false : onCancelClick}
                  label={'cancel'}
                />

                <Button
                  id={'save_button'}
                  className='button button-primary ms-2'
                  onClick={updateContactInformation}
                  label={props.intl.messages['saveContact']}
                  disabled={isRequiredFieldsFilled() ? false : true}
                />
              </div>

              {!isAnExternalPage() && isSubmitted && (
                <SuccessModal
                  isLoading={isSendWithSuccess}
                  messageKey={successModalMessage}
                  onClose={() => history.goBack()}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-3 mt-5 pe-0'>
          {!isAnExternalPage() && (
            <RightMenu
              isEditMode
              openMobileMenu={() => {}}
              closeMobileMenu={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EditContactPageLayout);
