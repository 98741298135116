import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import Span from '../../../../atoms/spans/Span';
import Input from '../../../../atoms/inputs/Input';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import TextArea from '../../../../atoms/textareas/TextArea';
import { FINAL_CONSUMER_VAT } from '../../../../../constants/defaultValues';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import { Helper } from '../helper';
import LabelWithToggle, {
  TEXT_POSITION,
} from '../../../../molecules/labels/LabelWithToggle';

export const DetailsSection = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    fields,
    countries,
    onChangeField,
    phoneCountryCodes,
    isExternalPage,
  } = props;

  const handleFinalConsumerToggle = (toggled) => {
    onChangeField('fiscal_id', toggled ? FINAL_CONSUMER_VAT : '');
  };

  useMemo(() => {
    const isFinalConsumer = () => {
      return fields?.fiscal_id === FINAL_CONSUMER_VAT;
    };

    setIsChecked(isFinalConsumer());
  }, [fields?.fiscal_id]);

  return (
    <div className='form-container'>
      <div className='column'>
        <Paragraph className='text-header h4 mb-1'>
          {props.intl.messages['fiscalInformationTitle']}
        </Paragraph>
        <Span className='color-gray-400'>
          {props.intl.messages['fiscalInformationSubtitle']}
        </Span>
        <div className='sep-light mt-2' />
      </div>
      <div className='form-row row mt-4 --contact-toggle mx-0'>
        <div
          className={`col-sm-${!isExternalPage ? '6 ps-0 pe-2' : '12 px-0'}`}
        >
          <label className='text-label'>
            {props.intl.messages['fiscalNumber']}
          </label>
          <Input
            id='fiscal_id'
            type='text'
            controlled
            value={fields?.fiscal_id || ''}
            onChange={(e) => onChangeField('fiscal_id', e.target.value)}
            disabled={isChecked}
          />
          <div className='col-12 mt-2'>
            <LabelWithToggle
              label='finalConsumer'
              toggleID='final_consumer'
              isChecked={isChecked}
              onChange={handleFinalConsumerToggle}
              poition={TEXT_POSITION.RIGHT}
            />
          </div>
        </div>

        {!isExternalPage && (
          <div className='col-md-6 col-sm-12 px-0 ps-md-2'>
            <label className='text-label'>{props.intl.messages['code']}</label>
            <Input
              id='code'
              type='text'
              controlled
              value={fields?.code || ''}
              onChange={(e) => onChangeField('code', e.target.value)}
            />
          </div>
        )}

        <div className='col-sm-12 mt-3 px-0'>
          <label className='text-label'>{props.intl.messages['name']}*</label>
          <Input
            id='name'
            type='text'
            controlled
            value={fields?.name || ''}
            onChange={(e) => onChangeField('name', e.target.value)}
          />
        </div>

        <div className='d-flex col-12 justify-content-end mt-4 px-0'>
          <Span className='color-gray-400'>
            {props.intl.messages['requiredFields']}*
          </Span>
        </div>
      </div>

      <div className='column mt-2'>
        <Paragraph className='text-header h4 mb-1'>
          {props.intl.messages['details']}
        </Paragraph>

        <Span className='color-gray-400'>
          {props.intl.messages['detailsSubtitle']}
        </Span>
        <div className='sep-light mt-2' />
      </div>

      <div className='form-row row mx-0'>
        <div className='col-md-6 col-sm-12 mt-2 px-0 pe-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['email']}
          </label>
          <Input
            id='email'
            type='text'
            controlled
            value={fields?.email || ''}
            onChange={(e) => onChangeField('email', e.target.value)}
            disabled={isExternalPage}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-2 px-0 ps-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['telephone']}
          </label>
          <div className='row mx-0'>
            <div className='col-5 ps-0 pe-2'>
              <SelectWithValues
                id='telephone_codes_select'
                keyName='phone_country_id'
                valueName='phone_code'
                options={phoneCountryCodes}
                value={fields?.phone_country_id}
                onChange={(e) =>
                  onChangeField('phone_country_id', e.target.value)
                }
              />
            </div>
            <div className='col-7 ps-2 pe-0'>
              <Input
                id='telephone_number_input'
                type='number'
                placeholder='999999999'
                controlled
                value={fields?.phone || ''}
                onChange={(e) => onChangeField('phone', e.target.value)}
                disabled={!fields?.phone_country_id}
              />
            </div>
          </div>
        </div>

        <div className='col-md-6 col-sm-12 px-0 pe-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['address']}
          </label>
          <TextArea
            id='address'
            controlled
            defaultValue={fields?.address || ''}
            onChange={(e) => onChangeField('address', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 px-0 ps-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['cellphone']}
          </label>
          <div className='row mx-0'>
            <div className='col-5 ps-0 pe-2'>
              <SelectWithValues
                id='cellphone_codes_select'
                keyName='mobile_country_id'
                valueName='phone_code'
                options={phoneCountryCodes}
                value={fields?.mobile_country_id}
                onChange={(e) =>
                  onChangeField('mobile_country_id', e.target.value)
                }
              />
            </div>
            <div className='col-7 ps-2 pe-0'>
              <Input
                id='cellphone_number_input'
                type='number'
                placeholder='999999999'
                controlled
                value={fields?.mobile || ''}
                onChange={(e) => onChangeField('mobile', e.target.value)}
                disabled={!fields?.mobile_country_id}
              />
            </div>
          </div>
        </div>

        <div className='col-md-6 col-sm-12 px-0 pe-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['city']}
          </label>
          <Input
            id='city'
            type='text'
            controlled
            value={fields?.city || ''}
            onChange={(e) => onChangeField('city', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 px-0 ps-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['postalCode']}
          </label>
          <Input
            id='postal_code'
            type='text'
            controlled
            value={fields?.postal_code || ''}
            onChange={(e) => onChangeField('postal_code', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 px-0 pe-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['country']}
          </label>
          <SelectWithValues
            id='country_id_select'
            keyName='id'
            valueName='name'
            options={countries}
            value={Helper.getCountryByName(countries, fields?.country)?.value}
            onChange={(e) => onChangeField('country', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 px-0 ps-md-2'>
          <label className='text-label mt-3'>
            {props.intl.messages['website']}
          </label>
          <Input
            id='website'
            type='text'
            controlled
            value={fields?.website || ''}
            onChange={(e) => onChangeField('website', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DetailsSection);
